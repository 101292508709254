@import 'style/fonts';
@import 'style/sizes';

.container {
  position: relative;
  height: 65px;
  padding: 15px $page-margin-x 10px $page-margin-x;
  font-size: 14px;
  font-weight: $semi-bold;
  background: #fff;
  border-bottom: 1px solid #eaeaea;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  font-size: $font-size-lg;
  font-weight: $semi-bold;
  text-transform: capitalize;

  svg {
    margin-right: 5px;
  }
}

.leftComponent {
  padding-left: 10px;
}

.toolbar {
  padding: 16px $page-margin-x 0 $page-margin-x;
  background: $light;
}

.center {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0 -50% 0 0;
  transform: translate(-50%, 0);
}
