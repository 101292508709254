@import 'style/colors';
@import 'style/sizes';

.app {
  display: flex;
  align-items: stretch;
  height: calc(100vh - calc(100vh - 100%));
}

:export {
  primary-blue: $blue-500;
  danger: $danger;
  red-50: $red-50;
  red-100: $red-100;
  red-200: $red-200;
  red-300: $red-300;
  red-400: $red-400;
  red-500: $red-500;
  red-600: $red-600;
  red-700: $red-700;
  red-800: $red-800;
  red-900: $red-900;
  warning: $warning;
  yellow-50: $yellow-50;
  yellow-100: $yellow-100;
  yellow-200: $yellow-200;
  yellow-300: $yellow-300;
  yellow-400: $yellow-400;
  yellow-500: $yellow-500;
  yellow-600: $yellow-600;
  yellow-700: $yellow-700;
  yellow-800: $yellow-800;
  yellow-900: $yellow-900;
  success: $success;
  green-50: $green-50;
  green-100: $green-100;
  green-200: $green-200;
  green-300: $green-300;
  green-400: $green-400;
  green-500: $green-500;
  green-600: $green-600;
  green-700: $green-700;
  green-800: $green-800;
  green-900: $green-900;
  info: $info;
  cyan-50: $cyan-50;
  cyan-100: $cyan-100;
  cyan-200: $cyan-200;
  cyan-300: $cyan-300;
  cyan-400: $cyan-400;
  cyan-500: $cyan-500;
  cyan-600: $cyan-600;
  cyan-700: $cyan-700;
  cyan-800: $cyan-800;
  cyan-900: $cyan-900;

  // https://www.figma.com/file/E50AceJDqV9RHPYsYE8kmj/Design-system?type=design&node-id=15%3A9&t=QuyVCmsYCCktmyy5-1
  secondary: $secondary;
  secondary-50: $secondary-50;
  secondary-100: $secondary-100;
  secondary-200: $secondary-200;
  secondary-300: $secondary-300;
  secondary-400: $secondary-400;
  secondary-500: $secondary-500;
  secondary-600: $secondary-600;
  secondary-700: $secondary-700;
  secondary-800: $secondary-800;
  secondary-900: $secondary-900;
  light: $light;
  blue-50: $blue-50;
  blue-100: $blue-100;
  blue-200: $blue-200;
  blue-300: $blue-300;
  blue-400: $blue-400;
  blue-500: $blue-500;
  blue-600: $blue-600;
  blue-700: $blue-700;
  blue-800: $blue-800;
  blue-900: $blue-900;
}
