@import 'style/sizes';

@mixin gallery-make-container($padding-x: $container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}

/* stylelint-disable scss/at-function-pattern, scss/no-global-function-names */
@function --gallery-breakpoint-min(
  $name,
  $breakpoints: $gallery-grid-breakpoints
) {
  $min: map-get($breakpoints, $name);

  @return if($min != 0, $min, null);
}

/* stylelint-enable scss/at-function-pattern, scss/no-global-function-names */

@mixin gallery-media-breakpoint-up(
  $name,
  $breakpoints: $gallery-grid-breakpoints
) {
  $min: --gallery-breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

/* mixin for multi line */
@mixin multi-line-ellipsis($line-height, $max-height, $lines-to-show) {
  /* stylelint-disable-next-line -- skip auto fix */
  display: -webkit-box;
  max-height: $max-height;
  overflow: hidden;
  line-height: $line-height;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines-to-show;
  -webkit-box-orient: vertical;
}

/* mixin for single line */
@mixin single-line-ellipsis($white-space) {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: $white-space;
}

/* mixin for flex center */
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  mixin for position y center */
@mixin position-y-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
